import { useCallback, useEffect, useState } from 'react';

import Buttons from 'components/Buttons';
import CancelButton from 'components/CancelButton';
import SaveButton from 'components/SaveButton';

import isFunction from 'utilities/isFunction';

const CancelSaveButtons = ({
    className = '',
    isSaveDisabled,
    onCancel,
    onSave,
    saveText,
    formState
}) => {
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] =
        useState(isSaveDisabled ?? false);

    const handleCancel = useCallback(
        clickEvent => {
            if (isFunction(onCancel)) {
                onCancel(clickEvent);
            }
        },
        [onCancel]
    );

    const handleSave = useCallback(
        async (clickEvent) => {
            try {
                setIsSaveButtonDisabled(true);

                if (isFunction(onSave)) {
                    await onSave(clickEvent);
                }
            } catch (error) {
                console.error('Save failed:', error);
            } finally {
                setIsSaveButtonDisabled(false);
            }
        },
        [onSave]
    );

    useEffect(() => {
        setIsSaveButtonDisabled(isSaveDisabled);
    }, [isSaveDisabled]);

    useEffect(() => {
        if (formState.isValid) {
            setIsSaveButtonDisabled(false);
        }
    }, [formState]);

    return (
        <Buttons className={className}>
            <CancelButton onClick={handleCancel} />

            <SaveButton
                isDisabled={isSaveButtonDisabled}
                onClick={handleSave}
                saveText={saveText}
            />
        </Buttons>
    );
};

export default CancelSaveButtons;
