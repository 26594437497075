import { useQuery } from '@tanstack/react-query';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import { buildGqlQuery, gqlClient } from 'utilities/gql/gql.util';
import { queryKeys } from './keys';

export function useGetPositions() {
    const { useTenants } = useGlobalStateHooks();
    const [tenants] = useTenants();

    const selectedSchema = tenants && tenants.length === 1 ? tenants[0] : undefined;
    const enabled = selectedSchema !== undefined;

    return useQuery({
        enabled,
        queryFn: async () => {
            const gqlQuery = buildGqlQuery({
                query: () => {
                    return {
                        query: {
                            __name: 'getAllPositions',
                            [`${selectedSchema}_position`]: { id: true, title: true }
                        }
                    };
                }
            });
            const client = gqlClient({
                accessToken: localStorage.accessToken,
                url: process.env.REACT_APP_GRAPHQL_URL
            });

            const res = await client.request(gqlQuery);
            return res;
        },
        queryKey: queryKeys.positions.getPositions(selectedSchema),
        select: (data) => {
            return data[`${selectedSchema}_position`];
        }
    });
}
