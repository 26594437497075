import { useQuery } from '@apollo/client';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import generateQueryFromDict from 'utilities/generateQueryFromDict';

const query = (tenantSchema, userGQL) => `
    ${tenantSchema}_user_activity(where: {user_id: {_eq: $userId}, status: {_eq: "inprogress"}, activity: {start_date: {_lte: "now()"}, closed: {_eq: false}, deleted: {_eq: false}}}) {
        activity {
        name
        due_date
        group_completion_rate
        id
        is_hierarchical
        user_activities(where: {status: {_neq: "removed"}}) {
            status
            assignment_type
            tenant_user {
                id
                ${userGQL}
            }
            group {
                name
                id
            }
        }
        }
    }
    ${tenantSchema}_user_case(where: {user_id: {_eq: $userId}, case: {created_date: {_lte: "now()"}, closed: {_eq: false}, deleted: {_eq: false}}}) {
        case {
        caseTypeByCaseType {
            name
        }
        due_date
        id
        }
    }
    ${tenantSchema}_marketing_request_assigned_to(where: {user_id: {_eq: $userId}, _or: [{status: {_eq: "review"}}, {status: {_eq: "respond"}}], marketing_request: {status: {_eq: "draft"}}}) {
        marketing_request {
        request_name
        due_date
        high_importance
        id
        }
    }
`;

const useData = () => {
    const { useQuerySchemas, useSchema, useTenants, useUserSetSchema, useUserId } = useGlobalStateHooks();

    const [querySchemas] = useQuerySchemas()
    const [schema] = useSchema();
    const [tenants] = useTenants();
    const [userId] = useUserId();
    const [userSetSchema] = useUserSetSchema();
    const usedSchema = userSetSchema ? userSetSchema : tenants && tenants.length === 1 ? tenants[0] : schema;
    var userGQL = '';
    if(querySchemas.length > 0){
        for(const s of querySchemas){
            userGQL += `users_${s.value} {
                first_name 
                last_name 
                profile_photo
            }`
        }
    }else if(usedSchema){
        userGQL += `users_${usedSchema} {
            first_name
            last_name
            profile_photo
        }`
    }

    const gqlQuery = generateQueryFromDict({customGqlContainer:true, query, schema:usedSchema, tenants, userGQL, variables:`$userId: Int!`})
    return useQuery(gqlQuery, {fetchPolicy: 'no-cache',
        variables: { userId }
    });
};

export default useData;
