import { useCallback } from 'react';

import CancelDeleteButtons from 'components/CancelDeleteButtons';
import ImportantText from 'components/ImportantText';
import Modal from 'components/Modal';
import PanelBody from 'components/PanelBody';
import PanelTitle from 'components/PageTitle';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import isFunction from 'utilities/isFunction';

import useData from './hooks/useData';
import useTranslation from './hooks/useTranslation';

import styles from './styles.module.scss';

const DeleteActivityModal = ({ activityName, id, onClose, schema, onSuccess }) => {
    const { ARE_YOU_SURE, DELETE_ACTIVITY } = useTranslation();

    const { useUserId } = useGlobalStateHooks();
    const [userId] = useUserId();

    const [_deletedActivity, deleteActivity] = useData(schema);

    const handleClose = useCallback(
        event => {
            if (isFunction(onClose)) {
                return onClose(event);
            }
        },
        [onClose]
    );

    const handleDelete = useCallback(() => {
        const formObject = {activityId:id, userId};

        deleteActivity(formObject, onSuccess);

    }, [deleteActivity, id, userId, onSuccess]);

    return (
        <Modal isOpen={true} onClose={handleClose}>
            <PanelBody>
                <PanelTitle className={styles.panelTitle} text={DELETE_ACTIVITY} />

                <ImportantText text={`${ARE_YOU_SURE} ${activityName}.`} />

                <CancelDeleteButtons
                    className={styles.cancelSaveButtons}
                    onCancel={handleClose}
                    onDelete={handleDelete}
                />
            </PanelBody>
        </Modal>
    );
};

export default DeleteActivityModal;