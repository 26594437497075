import ContentPanel from 'components/ContentPanel';
import PanelBody from 'components/PanelBody';
import { useActivitiesTable } from 'hooks/activities/activites.hook';
import { ActivitiesPanelHeader } from './activities-panel-header.component';
import { ActivitiesTable } from './activities-table.component';
import styles from './styles.module.scss';

export const ActivitiesPanel = ({
    className = '',
    filtersState = [],
    isMultiTenant = false,
    onTogleIsMe,
    schemas = []
}) => {
    const [filters, setFilterValue] = filtersState;

    const overdueTable = useActivitiesTable(filters.overdue);
    const pendingTable = useActivitiesTable(filters.pending);
    const upcomingTable = useActivitiesTable(filters.upcoming);
    const completedTable = useActivitiesTable(filters.completed);

    const displayOverdue =
        (filters.global.activityStatus.length === 0 ||
            filters.global.activityStatus?.includes('overdue')) &&
        overdueTable.activities.length > 0;

    const displayPending =
        (filters.global.activityStatus.length === 0 ||
            filters.global.activityStatus?.includes('pending')) &&
        pendingTable.activities.length > 0;

    const displayUpcoming =
        (filters.global.activityStatus.length === 0 ||
            filters.global.activityStatus?.includes('upcoming')) &&
        upcomingTable.activities.length > 0;

    const displayCompleted =
        (filters.global.activityStatus.length === 0 ||
            filters.global.activityStatus?.includes('completed')) &&
        completedTable.activities.length > 0;

    const isEmpty = Object.entries({
        completed: completedTable.activities.length,
        overdue: overdueTable.activities.length,
        pending: pendingTable.activities.length,
        upcoming: upcomingTable.activities.length
    })
        .filter(
            ([key]) =>
                filters.global.activityStatus.length === 0 ||
                filters.global.activityStatus.includes(key)
        )
        .every(([_key, value]) => value === 0);

    return (
        <ContentPanel className={`${className} ${styles.addActivitiesPanel}`}>
            <ActivitiesPanelHeader
                endDate={filters.global.activityDueDateTo}
                isAssignedToMe={filters.global.isAssignedToMe}
                isFlagged={filters.global.isFlagged}
                onChangeActivityType={setFilterValue('activityTypes')}
                onChangeAssignees={setFilterValue('activityAssignees')}
                onChangeEndDate={setFilterValue('activityDueDateTo')}
                onChangeEntities={setFilterValue('entityName')}
                onChangeRecurrence={setFilterValue('activityRecurrence')}
                onChangeReviewers={setFilterValue('activityReviewers')}
                onChangeSearch={setFilterValue('activityName')}
                onChangeStartDate={setFilterValue('activityDueDateFrom')}
                onChangeStatus={setFilterValue('activityStatus')}
                onToggleIsFlagged={() =>
                    setFilterValue('isFlagged')(!filters.global.isFlagged)
                }
                onToggleIsMe={onTogleIsMe}
                schemas={schemas}
                startDate={filters.global.activityDueDateFrom}
            />

            <PanelBody
                isLoadingWithText={isEmpty ? 'No activities found.' : ''}
            >
                {displayOverdue && (
                    <ActivitiesTable
                        activities={overdueTable.activities}
                        fetchNextPage={overdueTable.fetchNextPage}
                        hasNextPage={overdueTable.hasNextPage}
                        isFetching={overdueTable.isFetching}
                        isFetchingNextPage={overdueTable.isFetchingNextPage}
                        isMultiTenant={isMultiTenant}
                        onCompleteActivity={overdueTable.onCompleteActivity}
                        onToggleFlag={overdueTable.onToggleFlag}
                        setActivities={overdueTable.setActivities}
                        title="Overdue"
                    />
                )}

                {displayPending && (
                    <ActivitiesTable
                        activities={pendingTable.activities}
                        fetchNextPage={pendingTable.fetchNextPage}
                        hasNextPage={pendingTable.hasNextPage}
                        isFetching={pendingTable.isFetching}
                        isFetchingNextPage={pendingTable.isFetchingNextPage}
                        isMultiTenant={isMultiTenant}
                        onCompleteActivity={pendingTable.onCompleteActivity}
                        onToggleFlag={pendingTable.onToggleFlag}
                        setActivities={pendingTable.setActivities}
                        title="Pending"
                    />
                )}

                {displayUpcoming && (
                    <ActivitiesTable
                        activities={upcomingTable.activities}
                        fetchNextPage={upcomingTable.fetchNextPage}
                        hasNextPage={upcomingTable.hasNextPage}
                        isFetching={upcomingTable.isFetching}
                        isFetchingNextPage={upcomingTable.isFetchingNextPage}
                        isMultiTenant={isMultiTenant}
                        onCompleteActivity={upcomingTable.onCompleteActivity}
                        onToggleFlag={upcomingTable.onToggleFlag}
                        setActivities={upcomingTable.setActivities}
                        title="Upcoming"
                    />
                )}

                {displayCompleted && (
                    <ActivitiesTable
                        activities={completedTable.activities}
                        fetchNextPage={completedTable.fetchNextPage}
                        hasNextPage={completedTable.hasNextPage}
                        isFetching={completedTable.isFetching}
                        isFetchingNextPage={completedTable.isFetchingNextPage}
                        isMultiTenant={isMultiTenant}
                        onCompleteActivity={completedTable.onCompleteActivity}
                        onToggleFlag={completedTable.onToggleFlag}
                        setActivities={completedTable.setActivities}
                        title="Completed"
                    />
                )}
            </PanelBody>
        </ContentPanel>
    );
};
