import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useConfig from 'hooks/useConfig';
import useGlobalStateHooks from 'hooks/useGlobalStateHooks';
import useDataCreate from './hooks/useData';
import useForm from 'hooks/useForm';
import useRoles from './hooks/useRoles';

import useData from 'components/EmployeesPanel/hooks/useData';
import CancelSaveButtons from 'components/CancelSaveButtons';
import PageTitle from 'components/PageTitle';
import PersonalInformationPanel from 'components/PersonalInformationPanel';
import normalizeData from 'components/EmployeesPanel/data';

import { useGetDepartments } from 'queries/departments.query';
import { useGetPositions } from 'queries/positions.query';

import translations from './constants';
import styles from './styles.module.scss';

const AddPerson = () => {
    const { ADD_NEW_PERSON } = translations();
    const { useSchema, useQuerySchemas, useTenants } = useGlobalStateHooks();

    const { data: departments } = useGetDepartments();
    const { data: positions } = useGetPositions();

    const [querySchemas] = useQuerySchemas()
    const [schema] = useSchema();
    const [tenants] = useTenants();

    const { HUMAN_RESOURCES, INTERNAL_SERVER_ERROR } = useConfig();

    const [errorMessage, setErrorMessage] = useState();
    const [people, setPeople] = useState();
    const { loading, error, data } = useData();
    const [toggle, setToggle] = useState(false);

    const [_addedPerson, addPerson] = useDataCreate();

    const {data: rolesData} = useRoles();

    const navigate = useNavigate();

    const form = useForm({
        emailAddress: '',
        firstName: '',
        lastName: '',
        role: '',
    });

    const { handleForm } = form;

    const handleCancel = useCallback(() => {
        navigate(HUMAN_RESOURCES);
    }, [HUMAN_RESOURCES, navigate]);

    const handleSave = useCallback(() => {
        setErrorMessage();
        const output = handleForm();

        if (typeof output === 'string') {
            setErrorMessage(output);

            return;
        }
        const formObject = {};

        output.forEach((value, key) => {
            if(value === "" || value === null){
                setErrorMessage("Please fill all required fields");
                return;
            }
            return formObject[key] = value
        });

        formObject['departmentId'] = departments && departments.length > 0 ? departments[0].id : 1;
        formObject['positionId'] = positions && positions.length > 0 ? positions[0].id : 1;
        formObject['emailAddress'] = formObject['emailAddress'].toLowerCase();
        formObject['userName'] = formObject['emailAddress'].split("@")[0];
        formObject['hasAllRole'] = toggle;
        const emailExists = people.some(person => person.email === formObject.emailAddress);
        const usernameExists = people.some(person => person.username === formObject.userName);
    
        
        if (emailExists) {
            setErrorMessage("The email address already exists.");
            return;
        }
        
        if (usernameExists) {
            setErrorMessage("The username already exists.");
            return;
        }
        
        addPerson(formObject);
        navigate(HUMAN_RESOURCES);

    }, [addPerson, handleForm, navigate, people, toggle]);

    useEffect(() => {
        if (data) {
            const normalizedData = normalizeData({
                data,
                querySchemas,
                schema,
                tenants
            });

            setPeople(normalizedData?.people);
        }
    }, [data, querySchemas, schema, setPeople, tenants]);

    if (error) {
        navigate(INTERNAL_SERVER_ERROR);
        return;
    }

    if(loading){
        return <div>Loading...</div>
    }

    return (
        <div className={styles.addPerson}>
            <PageTitle className={styles.pageTitle} text={ADD_NEW_PERSON} />

            <CancelSaveButtons
                className={styles.cancelSaveButtons}
                onCancel={handleCancel}
                onSave={handleSave}
            />

            {rolesData && (
                <PersonalInformationPanel
                    className={styles.personalInformationPanel}
                    errorMessage={errorMessage}
                    form={form}
                    isAllRoleSelected={toggle}
                    rolesData={rolesData.role}
                    setIsAllRoleSelected={setToggle}
                />
            )}
        </div>
    );
};

export default AddPerson;
